
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import LoginFunctions from "./functions/LoginFunctions";
import Loading from "../../components/Loading";
const initialState = {
  name: '',
  email:'',
  password:'',
  c_password:'',
  isLoading:false,
}
class Register extends React.Component {

  constructor() {
    super();
    this.state={
      ...initialState,
      errors:[],
    }
  }

  onChangeHandler = (e) => this.setState({[e.target.name]: e.target.value, errors:[]});

  onSubmit = (e) => {
    this.setState({errors:[], isLoading: true});
    const {email, name, password, c_password} = this.state;
    const data = {email, name, password, c_password}
    this.register(data);
  }

  register = (data) => {
    new LoginFunctions().register(data, (error) => this.errorResponse(error));
  }

  errorResponse = (errors) => this.setState({errors, ...initialState})

  componentDidMount() {
    document.body.classList.toggle("register-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  render() {
    return (
        <div className="register-page">
          <Container>
            <Row>
              <Col className="ml-auto" lg="5" md="5">
              </Col>
              <Col className="mr-auto" lg="4" md="6">
                <Card className="card-signup text-center">
                  <CardHeader>
                    <CardTitle tag="h4">Register</CardTitle>
                    <h5 className="header text-center text-danger">
                      {this.state.errors && this.state.errors.message && this.state.errors.message}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Form action="" className="form" method="" onSubmit={e => e.preventDefault(this.onSubmit)}>
                      <InputGroup
                        className={
                          this.state.errors.data && this.state.errors.data.name && "has-danger"}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={
                            this.state.errors.data &&
                            this.state.errors.data.name ?
                            this.state.errors.data.name[0] :
                            "Name..."
                          }
                          type="text"
                          name={'name'}
                          value={this.state.name}
                          onChange={this.onChangeHandler}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          this.state.errors.data && this.state.errors.data.email && "has-danger"}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={
                            this.state.errors.data &&
                            this.state.errors.data.email ?
                            this.state.errors.data.email[0] :
                            "Email..."
                          }
                          type="text"
                          name={'email'}
                          value={this.state.email}
                          onChange={this.onChangeHandler}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          this.state.errors.data && this.state.errors.data.password && "has-danger"}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-lock-circle-open"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={
                            this.state.errors.data &&
                            this.state.errors.data.password ?
                                this.state.errors.data.password[0] :
                                "Password..."
                          }
                          type="password"
                          name={'password'}
                          value={this.state.password}
                          onChange={this.onChangeHandler}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          this.state.errors.data && this.state.errors.data.c_password && "has-danger"}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-lock-circle-open"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={
                            this.state.errors.data &&
                            this.state.errors.data.c_password ?
                                this.state.errors.data.c_password[0] :
                                "Confirm Password..."
                          }
                          type="password"
                          name={'c_password'}
                          value={this.state.c_password}
                          onChange={this.onChangeHandler}
                        />
                      </InputGroup>
                      <Button
                        type={'submit'}
                        className="btn-round"
                        color="info"
                        onClick={() => this.onSubmit()}
                      >
                        Get Started
                      </Button>
                    </Form>
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Loading isLoading={this.state.isLoading} />
          </Container>
          <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${
                    require("assets/img/bg/bruno-abatti.jpg").default
                })`,
              }}
          />
        </div>
    );
  }
}

export default Register;
