import { combineReducers } from "redux";
import AuthReducers from "./AuthReducer";
import CategoryReducer from "./CategoryReducer";
import ServiceReducer from "./ServiceReducer";
import UserReducer from "./UserReducer";
import BillReducer from "./BillReducer";
import ServiceUserReducer from "./ServiceUserReducer";
import OrderUserReducer from "./OrderUserReducer";
import BillUserReducer from "./BillUserReducer";
import OrderReducer from "./OrderReducer";
import UserServices from "./UserServices";
import AdminUserOrderReducer from "./AdminUserOrderReducer";
import DashboardReducer from "./DashboardReducer";

const AllReducers = combineReducers({
    auth: AuthReducers,
    categories: CategoryReducer,
    services: ServiceReducer,
    users: UserReducer,
    bills: BillReducer,
    services_user: ServiceUserReducer,
    order_user: OrderUserReducer,
    bill_user: BillUserReducer,
    orders: OrderReducer,
    userServices: UserServices,
    admin_user_orders: AdminUserOrderReducer,
    dashboardItems: DashboardReducer
});

export default AllReducers;