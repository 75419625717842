import Base from "./../../../functions/Base"
import store from "../../../store"
import cookie from "js-cookie";

export default class LoginFunctions extends Base {
  getProfile(errorResponse){
    this.instance.get('/profile')
      .then(res => {

        cookie.set("token", res.data.data.token);
        store.dispatch({
          type: "SET_LOGIN",
          payload: res.data.data.user
        });
        return res;
        })
      .catch(err => {
        err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
      })
  }
  login(data, errorResponse){
    this.instance.post('/login', data).then(res => {
      cookie.set("token", res.data.data.token);
      store.dispatch({
        type: "SET_LOGIN",
        payload: res.data.data.user
      });
      return res;
    }).catch(err => {
      err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
    });
  }

  register (data, errorResponse){
    this.instance.post('/regis', data).then(res => {
      cookie.set("token", res.data.data.token);
      store.dispatch({
        type: "SET_LOGIN",
        payload: res.data.data.user
      });
    }).catch(err => {
      if (err.response){
        errorResponse(err.response.data);
      } else {
        const  errors={
          message: "Error Network..."
        }
        errorResponse(errors);
      }
    })
  }
}