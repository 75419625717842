import Base from "../../../functions/Base";
import store from "../../../store";

export default class ServiceUserFunctions extends Base {
  get(data, errorResponse){
    this.instance.get('/services').then(res => {
      store.dispatch({
        type: "SET_SERVICES_USER",
        payload: res.data.data
      });
    }).catch(err => {
      err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
    });
  }

  store(data, successResponse, errorResponse){
    this.instance.post(`orders`, data).then(res => {
      console.log(res);
      successResponse(res.data)
    }).catch(err => {
      console.log(err.response);
      err.response ?  errorResponse(err.response.data.data.error) :  errorResponse("Error Network, try again...")
    })
  }
}