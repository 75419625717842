const AdminUserOrderReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_ADMIN_ORDERS_REDUCER":
      return {
        ...state,
        admin_user_orders: actions.payload
      };

    default:
      return state;
  }
};
export default AdminUserOrderReducer;