import React, {Component} from 'react';
import {connect} from "react-redux";
import ServiceUserFunctions from "./ServiceUserFunctions";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Input, Row} from "reactstrap";
import Select from "react-select";

class ServiceUserIndex extends Component {

  constructor(props) {
    super(props);
    this.state={
      services_user:props.services_user,
      service:[],
      imei:'',
      error:'',
      success:'',
    }
  }

  componentDidMount() {
    new ServiceUserFunctions().get();
  }

  onChange = e => this.setState({
    [e.target.name]: e.target.value,
    error:'',
    success:''
  });

  onSubmit = () => {
    this.setState({error:'',success:''})
    const {service_id} = this.state.service;
    const {imei} = this.state;
    const data = {imei, service_id};
    new ServiceUserFunctions().store(
      data,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
  }

  successResponse = data => this.setState({success: data.message, imei:''});

  errorResponse = error => this.setState({error, imei:''});

  render() {
    const {services_user} = this.props
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Services</CardTitle>
              </CardHeader>
              <CardBody>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="category_id"
                  value={this.state.service}
                  onChange={(value) => this.setState({service: value})}
                  options={
                    services_user.map(service => {
                      return {
                        value: service.id,
                        label: `$${service.price} -> ${service.service.name}`,
                        name: service.service.name,
                        price: service.price,
                        service_id: service.service.id
                      }
                    })
                  }
                  placeholder="Service"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {
          this.state.service &&
            this.state.service.price &&
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <p>
                      Service Name: {this.state.service.name}
                    </p>
                    <p>
                      Service Price: ${this.state.service.price}
                    </p>
                  </CardTitle>
                </CardHeader>
                <CardBody>

                  <br />
                  <Input
                    className="mx-4"
                    name={'imei'}
                    placeholder={'Place imeis'}
                    type={'textarea'}
                    onChange={this.onChange}
                    value={this.state.bulk}
                  />
                  {
                    this.state.error &&
                      <p className={'text-danger'}>
                        {this.state.error}
                      </p>
                  }
                  {
                    this.state.success &&
                    <p className={'text-success'}>
                      {this.state.success}
                    </p>
                  }
                </CardBody>
                <CardFooter>
                  <Button
                    rounded
                    size={'sm'}
                    color={'info'}
                    disabled={!this.state.imei}
                    onClick={() => this.onSubmit()}
                  >
                    Send
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    services_user: state.services_user.services_user
  }
}

export default connect(mapStateToProps)(ServiceUserIndex);