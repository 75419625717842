import Base from "../../../functions/Base";
import store from "../../../store";
export default class OrderUserFunctions extends Base {
  get(){
    this.instance.get('/orders').then(res => {
      store.dispatch({
        type: "SET_ORDER_USER",
        payload: res.data.data
      });
    })
  }

  filter(data) {
    this.instance.get(`user_filter_orders?imei=${data.imei}&status=${data.status}&service_id=${data.service_id}`, data).then(res => {
      store.dispatch({
        type: "SET_ORDER_USER",
        payload: res.data.data
      });
    })
  }
}