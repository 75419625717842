import React, {Component} from 'react';
import {Button, Modal} from "reactstrap";
import CategoryFunctions from "./CategoryFunctions";

class DeleteCategoryModal extends Component {

  onSubmit = () => {
    new CategoryFunctions().delete(this.props.category.id)
    this.props.toggle(this.props.category);
  }
  render() {
    return (
      <div>
        <Modal
          className="modal-sm"
          modalClassName="modal-primary"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header justify-content-center">
            <div className=" ml-auto mr-auto">
              <p>{`Delete Category ${this.props.category ? this.props.category.name : ""}`}</p>
            </div>
          </div>
          <div className="modal-footer">
            <div className="left-side">
              <Button
                color="link"
                type="button"
                onClick={() => this.onSubmit()}
              >
                Delete
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggle(this.props.category)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>

      </div>
    );
  }
}

export default DeleteCategoryModal;