import React, {Component} from 'react';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class UpdatePriceModal extends Component {
  constructor() {
    super();
    this.state={
      service:[],
      price:''
    }
  }

  onChange = e => this.setState({[e.target.name]: e.target.value});

  onSubmit = () => {
    const data = {id: this.props.service.id, price:  this.state.price}
    console.log(this.props.service.id, this.state.price)
   this.props.onChangePrice(data)
  }

  render() {
    const {toggle, isOpen, service} = this.props
    return (
      <Modal
        size={'sm'}
        toggle={toggle}
        isOpen={isOpen}
        onOpened={() => this.setState({service, price: service.price})}
        onClosed={() => this.setState({service:[], price:''})}
      >
        <ModalHeader>
          Update Price
        </ModalHeader>
        <ModalBody>
          <Input
            value={this.state.price}
            onChange={this.onChange}
            name={'price'}

          />
        </ModalBody>
        <ModalFooter className={'text-center'}>
          <Button
            color={'danger'}
            size={'sm'}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            color={'primary'}
            size={'sm'}
            onClick={() => this.onSubmit()}
          >
            Save
          </Button>
        </ModalFooter>

      </Modal>
    );
  }
}

export default UpdatePriceModal;