import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const GuestRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                !rest.loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/admin/services",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};
const mapStoreToProps = state => {
    return {
        loggedIn: state.auth.loggedIn
    };
};
export default connect(mapStoreToProps)(GuestRoute);