
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Input, Table, Badge,
} from "reactstrap";

import axios from "axios";
import cookie from "js-cookie";
import {api} from "../../functions/Base";
import {initialData} from "../../variables/DashboardVariables";
import {CardDashboard} from "./CardDashboard";

class Dashboard extends React.Component {
  constructor() {
    console.log('constructor');
    super();
    this.state={
      isLoading: false,
      data:{
        ...initialData
      }
    }
  }

  onchange = (e, data) => {
    this.setState({[data]: e.format()});
  }

  componentDidMount() {
    this.getInitialData()
  }

  getInitialData = async () => {

    //new DashboardFunctions().getDashboardData();


    this.setState({isLoading: true});
    axios.get(`${api}/admin_dashboard`, {
      //withCredentials: true,
      headers:{
        Authorization: `Bearer ${cookie.get("token")}`,
      }
    }).then(res => {
      console.log(res.data.data);
      const data = res.data.data
      this.setState({data});
      //const { groupedWeek, usersCount, month,  orderSuccess, orders,} = res.data.data
      //this.setState({  groupedWeek,  usersCount, orderSuccess, month, orders})
    }).catch(err => {
      console.log(err);
    })
        .finally(() => this.setState({isLoading: false}))
  }

  render() {
    const {data, isLoading} = this.state;

    return (
      <>
        <div className="content">

          {
            //  Hoy
          }
          <CardTitle>Hoy</CardTitle>
          <CardDashboard
              isLoading={isLoading}
              orders={data.totalOrders}
          />

          {
            //  Ayer
          }
          <CardTitle>Ayer</CardTitle>
          <CardDashboard
              isLoading={isLoading}
              orders={data.yesterdayOrders}
          />

          {
            //  Esta semana
          }

          <CardTitle>Esta Semana</CardTitle>
          <CardDashboard
              isLoading={isLoading}
              orders={data.weekOrders}
          />

          {
            //  Este Mes
          }

          <CardTitle>Este Mes</CardTitle>
          <CardDashboard
              isLoading={isLoading}
              orders={data.monthOrders}
          />



        </div>
      </>
    );
  }
}

export default Dashboard;
