const AuthReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_USERS":
      return {
        ...state,
        users: actions.payload
      };
    case "SET_USER":
      return {
        ...state,
        user: actions.payload
      };

    default:
      return state;
  }
};
export default AuthReducer;