const CategoryReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: actions.payload
      };
    case "SET_CATEGORY":
      return {
        ...state,
        category: actions.payload
      };

    default:
      return state;
  }
};
export default CategoryReducer;