const UserServices = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_USER_SERVICES":
      return {
        ...state,
        userServices: actions.payload
      };


    default:
      return state;
  }
};
export default UserServices;