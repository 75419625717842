const ServiceUserReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_SERVICES_USER":
      return {
        ...state,
        services_user: actions.payload
      };

    default:
      return state;
  }
};
export default ServiceUserReducer;