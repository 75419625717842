const OrderReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_ORDERS_PENDING":
      return {
        ...state,
        orders: actions.payload
      };
    case "SET_ORDERS_HISTORY":
      return {
        ...state,
        order_history: actions.payload
      };

    default:
      return state;
  }
};
export default OrderReducer;