const ServiceReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_SERVICES":
      return {
        ...state,
        services: actions.payload
      };
    case "SET_SERVICE":
      return {
        ...state,
        service: actions.payload
      };

    default:
      return state;
  }
};
export default ServiceReducer;