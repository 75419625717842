import Base from "../../../functions/Base";
import store from "../../../store";
export default class ServiceFunctions extends Base {
  get(data, errorResponse){
    this.instance.get('/admin_services', data).then(res => {
      console.log(res)
      store.dispatch({
        type: "SET_SERVICES",
        payload: res.data.data
      });
    }).catch(err => {
      console.log(err.response)
      err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
    });
  }

  store (data, errorResponse, successResponse){
    this.instance.post('/admin_services', data).then(res => {
      console.log(res)
      store.dispatch({
        type: "SET_SERVICES",
        payload: res.data.data
      });
      successResponse(res.data.data)
    }).catch(err => {
      console.log(err.response)
      const  errors={
        message: "Error Network..."
      }
      err.response ? errorResponse(err.response.data) : errorResponse(errors);
    })
  }

  update (id, data, errorResponse){
    this.instance.patch(`/admin_services/${id}`, data).then(res => {
      console.log(res)
      store.dispatch({
        type: "SET_SERVICES",
        payload: res.data.data
      });
    }).catch(err => {
      console.log(err.response)
      const  errors={
        message: "Error Network..."
      }
      err.response ? errorResponse(err.response.data) : errorResponse(errors);
    })
  }

  delete (id){
    this.instance.delete(`admin_services/${id}`).then(res => {
      store.dispatch({
        type: "SET_SERVICES",
        payload: res.data.data
      });
    })
  }
}