import React, {Component} from 'react';
import {Button, Input, Modal} from "reactstrap";
import CategoryFunctions from "./CategoryFunctions";

class UpdateCategoryModal extends Component {

  constructor(props) {
    super(props);
    this.state={
      name:props.category ? props.category.name : ""
    }
  }

  onChange = e => this.setState({[e.target.name]: e.target.value});

  onSubmit = () => {
    const {name} = this.state;
    const data = {name};
    new CategoryFunctions().update(this.props.category.id, data, this.props.error, this.props.success);
    this.props.toggle(this.props.category)
  }

  render() {
    return (
      <div>
        <Modal
          className="modal-sm"
          modalClassName="modal-primary"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header justify-content-center">
            <div className=" ml-auto mr-auto">
              <p>{`Update Category ${this.props.category ? this.props.category.name : ""}`}</p>
            </div>
          </div>
          <div className="modal-body">
            <p>
              <Input
                placeholder={'Name'}
                value={this.state.name}
                onChange={this.onChange}
                name={'name'}
              />
            </p>
          </div>
          <div className="modal-footer">
            <div className="left-side">
              <Button
                color="link"
                type="button"
                onClick={() => this.onSubmit()}
              >
                Save
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggle(this.props.category)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default UpdateCategoryModal;