const BillUserReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_BILL_USER":
      return {
        ...state,
        bill_user: actions.payload
      };

    default:
      return state;
  }
};
export default BillUserReducer;