import React from "react";

// reactstrap components
import {Card, CardBody, CardFooter, Row, Col,} from "reactstrap";
import {connect} from "react-redux";
import LoginFunctions from "./functions/LoginFunctions";

class UserProfile extends React.Component {

  componentDidMount() {
    new LoginFunctions().getProfile((error) => this.error(error))
  }

  error = (error) => {
    console.log(error);
  }

  render() {
    const {user} = this.props;
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <div className="image">
                  <img
                    alt="..."
                    src={require("assets/img/bg/fog-low.png").default}
                  />
                </div>
                <CardBody>
                  <div className={'text-center mb-5 text-capitalize'}>
                    <div>
                      <h5 className="title">{user.name}</h5>
                    </div>
                    <p className="description h4 text-center">
                      CREDITS: {user.credits} <br/>
                    </p>
                  </div>
                  <hr />
                  <div className={'text-center text-capitalize mb-2'}>
                    API DHRU CREDENTIALS
                  </div>

                  <p className="list-group-item-dark text-center">
                    APIKEY: {user.api_key} <br/>
                  </p>
                  <p className="list-group-item-dark text-center">
                    URL: https://hackplayersunlock.com <br/>
                  </p>
                  <p className="list-group-item-dark text-center">
                    USERNAME: {user.name} <br/>
                  </p>
                </CardBody>
                <CardFooter>
                  <hr/>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps) (UserProfile);
