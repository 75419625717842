const DashboardReducer = (state = {}, actions) => {
    switch (actions.type) {
        case "SET_DASHBOARD":
            return {
                ...state,
                dashboardItems: actions.payload
            };

        default:
            return state;
    }
};
export default DashboardReducer;