import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import axios from 'axios';

import UserFunctions from "./UserFunctions";
import CreateUserModal from "./CreateUserModal";
import store from "../../../store";
import Loading from "../../../components/Loading";
import cookie from "js-cookie";
import {label, paginate} from "../../../functions/paginate";
import UpdatePriceModal from "./UpdatePriceModal";
import moment from "moment";

class AdminUserOrders extends Component {

  constructor(props) {
    super(props);
    this.state={
      user: props.location.state,
      admin_user_orders:props.admin_user_orders,
      updateModal: false,
      service:[],
      isLoading: false,
    }
    console.log(props)
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders = () => new UserFunctions().getUserOrders(this.state.user.id, (data) => this.successCallback(data));



  paginate = async url => {
    this.setState({isLoading: true});
    await paginate(url, "SET_ADMIN_ORDERS_REDUCER")
    this.setState({isLoading: false});
  }

  label = label => {
    if (label === "&laquo; Previous"){
      label = "<  Previous";
    } else if  (label === "Next &raquo;"){
      label = "Next  >";
    }
    return label;
  }

  changePrice = (data) => {
    new UserFunctions().changePrice(data.id, data);
  }

  status = status => {
    let res = {text: "Pending", color: "info"}
    if (status === 3) {
      res = {text: "Rejected", color: "danger"}
    } else if (status === 4){
      res = {text: "Completed", color: "success"}
    }
    return res;
  }

  successCallback = (data) => console.log(data);

  render() {
    const {admin_user_orders} = this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Services</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr className={'text-center'}>
                    <th>ID</th>
                    <th>Service</th>
                    <th>Imei</th>
                    <th>Response</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    admin_user_orders.data.map(order => {
                      const status = this.status(order.status)
                      return (
                        <tr className={'text-center'} key={`user-services-${order.id}`}>
                          <td>{order.id}</td>
                          <td className="col-md-1">{order.service.name}</td>
                          <td className="col-md-1">{order.imei}</td>
                          <td className="col-md-3">{order.code ? order.code : "No Contestado"}</td>
                          <td className="col-md-3">${order.price}</td>
                          <td className="col-md-1">
                            <Badge
                              color={status.color}
                            >
                              {status.text}
                            </Badge>
                          </td>
                          <td className="col-md-2">{moment(order.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>

                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className={'text-center'}>
                {
                  admin_user_orders.links.map(link => {
                    return (
                      <Button
                        size={'sm'}
                        disabled={!link.url}
                        className={'mx-1 btn-round'}
                        color={link.active ? 'secondary' : 'primary'}
                        onClick={() => this.paginate(link.url)}
                      >
                        {label(link.label)}
                      </Button>
                    )
                  })
                }
              </CardFooter>
              <Loading isLoading={this.state.isLoading} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    admin_user_orders: state.admin_user_orders.admin_user_orders
  }
}

export default connect(mapStateToProps) (AdminUserOrders);