export const initialData = {
    totalOrders: {
        orderSuccess: 0,
        orderSuccessClean: 0,
        orderSuccessBase: 0,
        orderSuccessPremium: 0,
        orderRejected: 0,
        gananciaTotal: 0,
        gananciaClean: 0,
        gananciaBase: 0,
        gananciaPremium: 0
    },
    yesterdayOrders: {
        orderSuccess: 0,
        orderSuccessClean: 0,
        orderSuccessBase: 0,
        orderSuccessPremium: 0,
        orderRejected: 0,
        gananciaTotal: 0,
        gananciaClean: 0,
        gananciaBase: 0,
        gananciaPremium: 0
    },
    weekOrders: {
        orderSuccess: 0,
        orderSuccessClean: 0,
        orderSuccessBase: 0,
        orderSuccessPremium: 0,
        orderRejected: 0,
        gananciaTotal: 0,
        gananciaClean: 0,
        gananciaBase: 0,
        gananciaPremium: 0
    },
    monthOrders: {
        orderSuccess: 0,
        orderSuccessClean: 0,
        orderSuccessBase: 0,
        orderSuccessPremium: 0,
        orderRejected: 0,
        gananciaTotal: 0,
        gananciaClean: 0,
        gananciaBase: 0,
        gananciaPremium: 0
    }
}