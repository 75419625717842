import React, {Component} from 'react';
import {Button, Modal} from "reactstrap";
import ServiceFunctions from "./ServiceFunctions";

class DeleteServiceModal extends Component {

  onSubmit = () => {
    new ServiceFunctions().delete(this.props.service.id)
    this.props.toggle(this.props.service);
  }
  render() {
    return (
      <div>
        <Modal
          className="modal-sm"
          modalClassName="modal-primary"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header justify-content-center">
            <div className=" ml-auto mr-auto">
              <p>{`Delete Category ${this.props.service ? this.props.service.name : ""}`}</p>
            </div>
          </div>
          <div className="modal-footer">
            <div className="left-side">
              <Button
                color="link"
                type="button"
                onClick={() => this.onSubmit()}
              >
                Delete
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggle(this.props.service)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DeleteServiceModal;