import { createStore } from "redux";
import AllReducers from "./reducers/AllReducers";

const initialState = {
    auth: {
        loggedIn: false,
        user: {}
    },
    categories:{
        categories:{
            data:[]
        },
        category:[]
    },
    services:{
        services:{
            data:[]
        },
        service:[]
    },
    users:{
        users:{
            data:[],
            links:[]
        }
    },
    bills:{
        bills:{
            data:[],
            links:[]
        },
        bill:[]
    },
    services_user:{
        services_user:[]
    },
    order_user:{
        order_user: {
            data:[],
            links:[]
        }
    },
    bill_user:{
        bill_user: {
            data:[],
            links:[]
        }
    },
    orders:{
        orders:[],
        order_history:{
            data:[],
            links:[]
        }
    },
    admin_user_orders :{
        admin_user_orders:{
            data:[],
            links:[]
        }
    },
    userServices:{
        userServices:{
            data:[],
            links:[]
        }
    },
    dashboardItems:{

    }
};
const store = createStore(
    AllReducers,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;