import axios from "axios";
import cookie from "js-cookie";
import store from "../store";

export const paginate =  (url, type) => {
   axios.get(url, {
    headers:{
      Authorization: `Bearer ${cookie.get("token")}`,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    }
  }).then(res => {
    store.dispatch({
      type: type,
      payload: res.data.data
    });
    return true
  }).catch(err => {
    console.log(err.response);
    return true
  })
}

export const postPaginate =  (url, type) => {
  axios.post(url, {
    headers:{
      Authorization: `Bearer ${cookie.get("token")}`,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    }
  }).then(res => {
    store.dispatch({
      type: type,
      payload: res.data.data
    });
    return true
  }).catch(err => {
    console.log(err.response);
    return true
  })
}

export const label = label => {
  if (label === "&laquo; Previous"){
    label = "<";
  } else if  (label === "Next &raquo;"){
    label = ">";
  }
  return label;
}