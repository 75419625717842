import Base from "../../../functions/Base";
import store from "../../../store";

export default class UserFunctions extends Base {
  get(data, errorResponse){
    this.instance.get('/admin_users', data).then(res => {
      console.log(res)
      store.dispatch({
        type: "SET_USERS",
        payload: res.data.data
      });
    }).catch(err => {
      console.log(err.response)
      err.response ?  errorResponse(err.response.data.message) :  errorResponse("Error Network, try again...")
    });
  }

  store (data, errorResponse, successResponse){
    this.instance.post('/admin_users', data).then(res => {
      console.log(res)
      store.dispatch({
        type: "SET_USERS",
        payload: res.data.data
      });
      successResponse(res.data.data)
    }).catch(err => {
      console.log(err.response)
      const  errors={
        message: "Error Network..."
      }
      err.response ? errorResponse(err.response.data) : errorResponse(errors);
    })
  }

  show(id, successCallback){
    this.instance.get(`admin_users/${id}`)
      .then(res => {
          console.log(res);
          successCallback(res);
      });
  }

  update (id, data, successCallback, errorCallback){
    this.instance.patch(`/admin_users/${id}`, data).then(res => {
      store.dispatch({
        type: "SET_USER",
        payload: res.data.data
      });
      successCallback(res)
    }).catch(err => {
      console.log(err.response)
       const  errors={
        message: "Error Network..."
      }
      err.response ? errorCallback(err.response.data) : errorCallback(errors);
    })
  }

  delete (id){
    this.instance.delete(`admin_users/${id}`).then(res => {
      store.dispatch({
        type: "SET_USERS",
        payload: res.data.data
      });
    })
  }
  getUserServices (id){
    this.instance.get(`admin_services_price_by_user/${id}`).then(res => {
      store.dispatch({
        type: "SET_USER_SERVICES",
        payload: res.data.data
      });
    })
  }

  changePrice(id, data) {
    this.instance.patch(`user_service_price/${id}`, data).then(res => {
      store.dispatch({
        type: "SET_USER_SERVICES",
        payload: res.data.data
      });
    })
  }

  getUserOrders (id) {
    this.instance.get(`admin_orders_by_user/${id}`)
      .then(res => {
        store.dispatch({
          type: "SET_ADMIN_ORDERS_REDUCER",
          payload: res.data.data
        })
      })
      .catch(err => console.log(err.response));
  }
}