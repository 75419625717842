import axios from "axios";
import cookie from "js-cookie";
export const api =  "https://esparza.fenoxcorp.com/api";

export default class Base {
    instance = axios.create({
        baseURL: api,
        timeout: 0,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${cookie.get("token")}`,
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        }
    });
}

export const instance = axios.create({
    baseURL: api,
    timeout: 0,
    withCredentials: true,
    headers: {
        Authorization: `Bearer ${cookie.get("token")}`,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    }
});