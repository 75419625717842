import React, {Component} from 'react';
import {Button, Input, Modal} from "reactstrap";
import ServiceFunctions from "./ServiceFunctions";
import Select from "react-select";

class CreateServiceModal extends Component {

  constructor() {
    super();
    this.state={
      name:'',
      price:'',
      category_id: '',
    }
  }

  onChange = e => this.setState({[e.target.name]: e.target.value});

  onSubmit = () => {
    const {name, price, category_id} = this.state;
    const data = {name, price, category_id };
    new ServiceFunctions().store(data, this.props.error, this.props.success);
    this.props.toggle()
  }

  render() {
    return (
      <div>
        <Modal
          className="modal-sm"
          modalClassName="modal-primary"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header justify-content-center">
            <div className="ml-auto mr-auto">
              <p>Create a new category</p>
            </div>
          </div>
          <div className="modal-body">
            <p>
              <Input
                placeholder={'Name'}
                value={this.state.name}
                onChange={this.onChange}
                name={'name'}
              />
            </p>
            <p>
              <Input
                placeholder={'Price'}
                value={this.state.price}
                onChange={this.onChange}
                name={'price'}
              />
            </p>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="category_id"
              value={this.state.category_id}
              onChange={(value) => this.setState({category_id: value.value})}
              options={
                this.props.categories.map(category => {
                  return {value: category.id, label: category.name}
                })
              }
              placeholder="Category"
            />
          </div>
          <div className="modal-footer">
            <div className="left-side">
              <Button
                color="link"
                type="button"
                onClick={() => this.onSubmit()}
              >
                Save
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={this.props.toggle}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CreateServiceModal;