import React, {Component} from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import axios from 'axios';

import UserFunctions from "./UserFunctions";
import CreateUserModal from "./CreateUserModal";
import store from "../../../store";
import Loading from "../../../components/Loading";
import cookie from "js-cookie";

class UserIndex extends Component {

  constructor(props) {
    super(props);
    this.state={
      services:props.services,
      categories: props.categories,
      createModal:false,
      updateModal: false,
      deleteModal: false,
      service:[],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => new UserFunctions().get((data) => this.successCallback(data));

  createUser = () => this.setState({createModal: !this.state.createModal});

  updateUser = user => {
    if (!user){
      user = []
    }
    this.setState({updateModal: !this.state.updateModal, user});
  }
  deleteUser = user => {
    if (!user){
      user = []
    }
    this.setState({deleteModal: !this.state.deleteModal, user});
  }

  paginate = async url => {
    this.setState({isLoading: true});
    await axios.get(url, {
      headers:{
        Authorization: `Bearer ${cookie.get("token")}`,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(res => {
      store.dispatch({
        type: "SET_USERS",
        payload: res.data.data
      });
    }).catch(err => {
      console.log(err.response);
    })
    this.setState({isLoading: false});
  }

  label = label => {
    if (label === "&laquo; Previous"){
      label = "<  Previous";
    } else if  (label === "Next &raquo;"){
      label = "Next  >";
    }
    return label;
  }

  render() {
    const {users} = this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Users</CardTitle>
                <Button onClick={() => this.createUser()}>Create new User</Button>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Credits</th>
                    <th className="text-right">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    users.data.map(user => {
                      return (
                        <tr key={`services-${user.id}`}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.credits}</td>
                          <td className="text-right">
                            <Button
                              size={'sm'}
                              color={'info'}
                              onClick={() => this.props.history.push(`admin-user/${user.id}`, user)}
                            >
                              Update
                            </Button>
                            <Button
                              size={'sm'}
                              color={'danger'}
                              onClick={() => this.deleteUser(user)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className={'text-center'}>
                {
                  users.links.map(link => {
                    return (
                      <Button
                        size={'sm'}
                        disabled={!link.active}
                        className={'mx-2 btn-round'}
                        color={'primary'}
                        onClick={() => this.paginate(link.url)}
                      >
                        {this.label(link.label)}
                      </Button>
                    )
                  })
                }
              </CardFooter>
              <CreateUserModal
                isOpen={this.state.createModal}

                toggle={() => this.createUser()}
                success={(data) => this.successCallback(data)}
                error={ (error) => this.errorCallback(error)}
              />
              <Loading isLoading={this.state.isLoading} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    user: state.auth.user
  }
}

export default connect(mapStateToProps) (UserIndex);