import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import moment from "moment";
import BillUserFunctions from "./BillUserFunctions";
import {label, paginate} from "../../../functions/paginate";

class BillUserIndex extends Component {

  constructor(props) {
    super(props);
    this.state={
      bill_user:props.bill_user,
    }
  }

  componentDidMount() {
    new BillUserFunctions().get()
  }

  paginate = async url => {
    this.setState({isLoading: true});
    await paginate(url, "SET_BILL_USER")
    this.setState({isLoading: false});
  }

  status = status => {
    let res = {text: "Pending", color: "info"}
    if (status === 3) {
      res = {text: "Rejected", color: "danger"}
    } else if (status === 4){
      res = {text: "Completed", color: "success"}
    }
    return res;
  }

  render() {
    const {bill_user} = this.props;

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Orders</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Created</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    bill_user.data.map(bill => {
                      return (
                        <tr className="text-center" key={`category-${bill.id}`}>
                          <td className="col-md-1">{bill.id}</td>
                          <td className="col-md-3">{bill.amount}</td>
                          <td>
                            <Badge
                              color={bill.status ? "success" : "danger"}
                              rounded
                            >
                              {bill.status ? "Success" : "Canceled"}
                            </Badge>
                          </td>

                          <td className="col-md-2">{moment(bill.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                          <td className="col-md-2">{moment(bill.updated_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className={'text-center'}>
                {
                  bill_user.links.map(link => {
                    return (
                      <Button
                        size={'sm'}
                        disabled={!link.url}
                        className={'mx-1 btn-round'}
                        color={link.active ? 'secondary' : 'primary'}
                        onClick={() => this.paginate(link.url)}
                      >
                        {label(link.label)}
                      </Button>
                    )
                  })
                }
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bill_user: state.bill_user.bill_user
  }
}

export default connect(mapStateToProps) (BillUserIndex);