import {Badge, Card, CardBody, CardFooter, CardTitle, Col, Row} from "reactstrap";
import React, {useEffect, useState} from "react";

export function CardDashboard({isLoading, orders}){

    return (
        <Row>
            <Col lg="12" md="12" sm="12">
                <Card className="card-stats">
                    <CardBody>
                        <Row className={"center"}>
                            <Col md="3" xs="12">
                                <div className="numbers">
                                    <p className="card-category">Ordenes Totales</p>
                                    <CardTitle tag="p">{isLoading ? "Cargando" : orders.orderSuccess + orders.orderRejected}</CardTitle>
                                    <p/>
                                </div>
                            </Col>
                            <Col md="3" xs="12">
                                <div className="numbers">
                                    <p className="card-category">Ordenes Completas</p>
                                    <CardTitle tag="p">{isLoading ? "Cargando" : orders.orderSuccess}</CardTitle>
                                    <p/>
                                </div>
                            </Col>
                            <Col md="3" xs="12">
                                <div className="numbers">
                                    <p className="card-category">Ordenes Rechazadas</p>
                                    <CardTitle tag="p">{isLoading ? "Cargando" : orders.orderRejected}</CardTitle>
                                    <p/>
                                </div>
                            </Col>
                            <Col md="3" xs="12">
                                <div className="numbers">
                                    <p className="card-category">Ganancia</p>
                                    <CardTitle tag="p">{isLoading ? "Cargando" : `$${orders.gananciaTotal}`}</CardTitle>
                                    <p/>
                                </div>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </CardBody>
                    <CardFooter>
                        <hr/>
                        <Row>
                            <Col>
                                <p>
                                    Clean: {orders.orderSuccessClean} - Ganancia: ${orders.gananciaClean}
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Base: {orders.orderSuccessBase} - Ganancia: ${orders.gananciaBase}
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Premium: {orders.orderSuccessPremium} - Ganancia: ${orders.gananciaPremium}
                                </p>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}